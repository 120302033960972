<template>
    <div class="bodypage">
    <main class="mt-0 main-content main-content-bg viewscreen">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row header">
              <div class="col">
                <img src="@/assets/img/logo_rev.svg" alt="logo" class="logo-img">
              </div>
            </div>
            <div class="row hbody">
              <div class="col">
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <!-- <h3 class="font-weight-bolder text-success text-gradient">
                      Login
                    </h3> -->
                    <p class="mb-0">
                      <!-- spiega -->
                    </p>
                  </div>
                  <div class="card-body context-plus-layout">
                    <div class="row">
                        <div class="col col-12 text-center">
                            <p>Ci dispiace, ma il viaggio in Notaropoly è terminato!</p>
                            <p>Potrai mettere alla prova le tue conoscenze su informatica notarile e notariato digitale in un'altra occasione.</p>
                            <p>A presto!</p>
                        </div>
                    </div>
                  </div>
                  
                  <form role="form" class="text-start">
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        @click="onClickLoginBtn"
                        role="link"
                        >GUARDA LA CLASSIFICA FINALE
                      </soft-button>
                    </div>
                  </form>
                  <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-4 text-sm">
                       <!-- sottospiega -->
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
  </template>

<script setup lang="ts">
import { CreateAxiosInstance } from '@/services/auth.module';
import { AuthService, ExternalAuthLoginInfoResponse, serviceOptions } from '@/services/swagger';
import { onBeforeMount, ref } from 'vue';
import SoftButton from '@/components/Buttons/SoftButton.vue';

const loading = ref(true);
const externalAuthInfo = ref<ExternalAuthLoginInfoResponse>(new ExternalAuthLoginInfoResponse());
const onClickLoginBtn = () => {
  if(externalAuthInfo.value && externalAuthInfo.value.externalProviderAuthUrl) {
    //location.href = externalAuthInfo.value.externalProviderAuthUrl
    window.open(externalAuthInfo.value?.externalProviderAuthUrl);
  }
}
const GetExternalAuthUrl = async () => {
  try{
    var res = await AuthService.secret();

    externalAuthInfo.value = res;
    loading.value = false;
  }
  catch(error){
    console.log(error);
  }

}
onBeforeMount(async()=>{
  serviceOptions.axios = CreateAxiosInstance();
  await GetExternalAuthUrl();
})
</script>

<style scoped>
.main {
    width: 100vw;
}

.card.card-plain {
    height: 95%;
    background-color: #00000070;
    margin-bottom: 5%;
    color: white;
    text-align: center;
}

.bodypage {
background-image: url("@/assets/img/fondo.jpg")!important;
background-color: #0b4d86!important;
background-size: contain;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: auto;
padding: 0;
margin: 0;
}

.main-content-bg.viewscreen {
    background-color: transparent;
    font-size: 150%;
}

body {
    background: transparent;
}
.card-body {
    width: 100%;
}

.card-header.text-start {
    text-align: center!important;
    padding-top: 3%;
}

.card-header.text-start p {
    width: 90%;
    margin: auto;
}

form.text-start {
    position: relative;
    width: calc(100% - 30px);
    bottom: 0;
}

.card-footer:last-child {
    position: relative;
    margin-top: 6vh;
}

.context-plus-layout .row, .context-plus-layout .col {
    height: 100%;
}
.card-body.context-plus-layout {
    margin-bottom: 5vh;
}

.card-body.context-plus-layout .col {
    margin-left: 30px;
    margin-right: 30px;
    width: calc( 100% - 60px);
    border-radius: 1em;
    background-color: #014482;
    box-shadow: 5px 5px 20px #00000055;
    margin-top: auto;
    padding-top: 8vh;
    position: relative;
    padding-left: 2em;
    padding-right: 2em;
}





.main-content-bg.viewscreen .page-header {
    height: 25vh;
}

.row.hbody {
    height: 75vh;
}

.card-header.text-start {
    margin-bottom: 1%;
    padding-bottom: 1%!important;
}

.row.header {
    height: 15vh;
    margin-top: 2vh;
}

.row.header > .col {
    text-align: center;
}

.row.header img.logo-img {
    height: 70%;
    width: auto;
    margin: auto;
}

button.btn.mb-0.bg-gradient-success.btn-md.w-100.null.my-4.mb-2 {
    background-color: #0084ff!important;
    color: white;
    font-size: 1em;
    width: auto!important;
    padding-left: 10%;
    padding-right: 10%;
    box-shadow: 5px 5px 1em #ffffff77;
    border: 2px solid white;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

@keyframes blink { 50% { border-color:#000 ; }  }

.col.col-4 {
    background-color: #ffffff55;
}

.card-header.text-start h3 {
    color: white!important;
}

main.main-content.main-content-bg.viewscreen {
    position: relative;
}

.onlymobile {
    display: none;
}

@media only screen and (max-width: 650px) {
    .card-body.context-plus-layout .col {
        /*width: calc( 100% - 60px); */
        height: auto;
        padding-top: 2em;
        padding-bottom: 2em;
    }
    
    .card-body.context-plus-layout .col:after {
        transform: rotate(90deg);
        left: 50%;
        bottom: -16px;
        top: auto;
    }
    .bodypage {
        font-size: 2vw!important;
    }
    .onlymobile
    {
      display: initial;
    }
}
</style>