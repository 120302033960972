<template>
  <div class="bodypage ranking">
    <div class="user-ranking">
      <div class="headlogo">
        <div class="row">
        <div class="col head">
          
        </div>
        <div class="col head-logo">
          <img src="@/assets/img/logo_rev.svg" alt="Notaropoly" class="logo-image">
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="animo">
            <video width="100%" height="100%" class="videoshow" autoplay muted noloop>
              <source src="@/assets/img/bckvideo1.mp4" type="video/mp4">
              <!-- Your browser does not support the video tag. -->
            </video>
          </div>
          <div class="user">
            <div class="score">{{ userScore.position }}</div>
            <img :src='(userScore.avatarFileName == null || String(userScore.avatarFileName).length == 0) ? require(`@/assets/img/default-avatar-profile.jpg`) : require(`@/assets/img/${userScore.avatarFileName}`)' alt="Profile Image" class="profile-image" :style="{ borderColor: userScore.userColor }">
              <div class="user-info">
                <div class="medal-box">
                  <img src="@/assets/img/medal.svg" alt="medal" class="medal-image">
                </div>
                <h3>{{ userScore.name }}</h3>
                <p>Punteggio: {{ formattedScore }}</p>
              </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button @click="rankingShow" class="btn me-button ranking-button">vai alla classifica</button>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { MatchService, UserScore, serviceOptions } from '@/services/swagger';
import { CreateAxiosInstance } from '@/services/auth.module';
import router from '@/router';  // Assicurati che il percorso del router sia corretto
// Importa i tuoi servizi e componenti necessari
serviceOptions.axios = CreateAxiosInstance();
const userScore = ref<UserScore>(new UserScore());

const formattedScore = computed(() => {
  if(userScore.value.score != undefined){
    return userScore.value.score.toFixed(3);
  }
  return  userScore.value.score;
})
const rankingShow = () => {
  // Aggiungi la logica per andare alla pagina ranking
  router.push("/ranking");
};

onMounted(async()=>{
  try{
    var score = await MatchService.endGame();
    userScore.value = score;
  }catch(err){
    console.log(err);
  }
})

</script>

<style scoped>
.user-ranking {
    width: 80%;
    padding: 4em;
    max-height: 100%;
    background-color: #74a2cdbb;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .user {
    display: flex;
    align-items: center;
    padding-left: 40px;
    margin-bottom: 0!important;
    padding-bottom: 0!important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .user-ranking .user:nth-child(1) {
    font-size: 1.5em;
    padding-left: 20px;
  }

  .user-ranking .user:nth-child(2) {
    font-size: 1.2em;
  }

  .user-ranking .user:nth-child(3) {
    font-size: 1.1em;
  }

  .user-ranking .user {
    font-size: 0.7em;
  }

  .profile-image {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 1px solid black;
  }

  .user-info {
    vertical-align: top;
    margin-top: 2em;
  }

  .user-info h3 {
    font-size: 2em;
  }

  .user-info p {
    margin-bottom: 0;
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  button {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .repeat-button {
    margin-top: 20px;
    background-color: #014482;
  }

  .repeat-button:hover {
    background-color: #159ffc;
  }

  .score {
    font-size: 3.5em;
    padding-right: 20px;
    color: white;
  }

  img.logo-image {
    max-width: 200px;
  }

  .col.head-logo {
    text-align: right;
  }

  .col.head h1 {
    font-size: 2.5em;
    font-weight: bold;
    color: #014482;
  }

  .bodypage {
    background-image: url("@/assets/img/fondo.jpg");
    background-color: #0b4d86!important;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .footer {
    width: 100%;
    text-align: center;
    padding-top: 1em;
  }

  
  .score {
    min-width: 80px
;}

.user-info img.medal-image {
    display: none;
    max-height: 1em;
}
.medal-box {display: block;float: right;width: 1em;}

.user-ranking .user h3 {
    display: block;
    float: left;
}

.user:nth-child(1) .medal-image, .user:nth-child(2) .medal-image, .user:nth-child(3) .medal-image{display: inline-block;}




.pagination .btn {
    border: 1px solid white!important;
    color: white;
    background-color: transparent;
}

.pagination button.btn[disabled] {
    background-color: #80808055;
    color: #c9c9c9;
}

.pagination span {
    color: white;
    display: inline-block;
    padding-top: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
}

button.btn:hover {
    background-color: #3498db;
}

img.medal-image {
    max-height: 1em;
    float: left;
}

.user-info img.medal-image {
    display: none;
}

svg.medal-image {
    fill: red!important;
}

.medal-box {display: block;float: right;width: 1em;}

.user-ranking .user h3 {
    display: block;
    float: left;
}


.pagination .btn {
    border: 1px solid white!important;
    color: white;
    background-color: transparent;
}

.pagination button.btn[disabled] {
    background-color: #80808055;
    color: #c9c9c9;
}

.pagination span {
    color: white;
    display: inline-block;
    padding-top: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
}

button.btn:hover {
    background-color: #3498db;
}

body.bg-gray-100 {
    background-color: #0b4d86 !important;
}

.bodypage.ranking {
    height: 100vh;
}

.user-ranking {
    height: calc(100vh - 12%);
}

.user {
    margin: 0 auto;
}

.content .row {
    height: 100%;
}

.content {
    height: 100%;
}

.user {
    margin: 0 auto;
    width: auto;
}

img.profile-image {
    width: 200px!important;
    height: 200px!important;
}


.user-info {
    font-size: 1.5em;
    color: white;
}

.user-info h3 {
    text-shadow: 5px 5px 10px black;
}

.score {
    background-color: #03d5ff;
    display: block;
    border-radius: 100%;
    width: 1.5em;
    text-align: center!important;
    height: 1.5em;
    /*vertical-align: middle;*/
    box-shadow: 10px 10px 10px black;
    position: absolute;
    z-index: 1;
    top: 40%;
    left: -80px;
    align-content: center;
    text-align: right!important;
}


.animo {
    height: 100%;
    width: 100%;
    position: absolute;
}

.user-ranking {
    background-color: #0fa4fd !important;
}

.user-ranking .user {
    transform: translateY(50px);
    border-bottom: none;
}
</style>
