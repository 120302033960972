<template>
    <div class="container my-5 adminbo">

        <div class="row mb-2">
            <div class="col-2">
                <router-link :to="{name: 'admin_index' }">CLASSIFICA UTENTI</router-link>
            </div>
            
            
            <div class="col-3">
                <router-link :to="{name: 'admin_registry' }">ANAGRAFICHE</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <h2 class="mb-4">Storico Dettagli Match</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-8 text-center">
                <div class="input-group mb-3">
                    <input type="text" v-model="matchId" class="form-control text-left" id="userSearch" placeholder="Inserisci l'id del match">
                    <button class="btn btn-outline-secondary" @click="search">Cerca</button>
                </div>
            </div>
            <div class="col-4 text-right mb-2">
                <button @click="downloadCsv" type="button" class="btn btn-primary">Estrai CSV Dettagli</button>
            </div>
        </div>
    
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
                <thead class="table-light">
                    <tr>
                        <th>ID Utente</th>
                        <th>Email</th>
                        <th>MissionId</th>
                        <th>QuestionId</th>
                        <th>QuestionText</th>
                        <th>Punteggio Parziale</th>
                        <th>Completato</th>
                        <th>Associazioni</th>
                        <th>Aperta</th>
                        <th>Chiusa</th>
                        <th>Millisecondi</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="el in data.userDetailMatchResponse" :key="el.id">
                        <tr>
                            <td>{{ el.idUser }}</td>
                            <td>{{ el.email }}</td>
                            <td>{{ el.missionId }}</td>
                            <td> {{ el.questionId }}</td>
                            <td>{{ el.questionText }}</td>
                            <td>{{ el.partialScore }}</td>
                            <td>{{ el.completed ? 'Completato' : "Non Completato" }}</td>
                            <td>{{ el.associations }}</td>
                            <td>{{ el.dateTime }}</td>
                            <td>{{ el.closedDateTime }}</td>
                            <td>{{ el.milliseconds }}</td>
                        </tr>
                    </template>

                    <!-- Aggiungi altre righe se necessario -->
                </tbody>
            </table>
           
        </div> 
            <div class="row">
                <div class="col">
                    <template v-if="pages > 1">
                        <page-nav-component
                            :per_page="paginationRequest.perPage ?? 0"
                            :total_items="data.totalResults ?? 0"
                            :number_of_pages="pages"
                            :current_page="paginationRequest.page ?? 1"
                            @go-to-page="goToPage"
                            @next="nextPage"
                            @previous="previous"
                        ></page-nav-component>
                    </template>
                </div>
            </div>
        </div>
</template>

<script setup lang="ts">
import { CreateAxiosInstance } from '@/services/auth.module';
import { HistoryAggregates, PaginationRequest, serviceOptions } from '@/services/swagger';
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { AdminService } from '@/services/swagger';
import { useRoute } from 'vue-router';

import PageNavComponent from '@/components/Navbars/PageNavComponent.vue';
import axios from 'axios';
import store from '@/store';
serviceOptions.axios = CreateAxiosInstance();

const data = ref<HistoryAggregates>(new HistoryAggregates);
const paginationRequest = reactive<PaginationRequest>(new PaginationRequest({page: 1, perPage: 10}));
const route = useRoute();
const matchId = ref<number|undefined>();


const pages = computed(()=>{
    if(data.value.totalResults && paginationRequest.perPage)
        return Math.ceil(data.value.totalResults / paginationRequest.perPage)
    else 
        return 0;
})
const downloadCsv = async () => {
    
    try {
        const WebServicesURL = process.env.VUE_APP_WEBSERVICES_URL;
        const response = await axios.get(WebServicesURL + '/api/Admin/dwl_details', {
            params: {
                id: matchId.value
            },
            responseType: 'blob', // Specifica che la risposta è un blob,
            headers: {
                'Authorization': `Bearer ${store.getters['UserModule/getUserToken']}`,
                'Content-Type': 'application/json'
            }
        });

        // Crea un URL per il blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Crea un elemento <a> per avviare il download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'details.csv');
        document.body.appendChild(link);
        link.click();

        // Rimuovi l'elemento <a> e rilascia l'URL del blob
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading CSV:', error);
      }
    
}
const search = async()=>{
    paginationRequest.page = 1;
    getData();
}
const goToPage = async (page: number) => {
    paginationRequest.page = page;
    await getData();
}
const nextPage = async () => {
    if(paginationRequest.page)
        paginationRequest.page++
    await getData();
}

const previous = async () => {
    if(paginationRequest.page)
        paginationRequest.page--
    await getData();
}

const getData = async() => {
    try{
        data.value = await AdminService.history({id: matchId.value, page: paginationRequest.page, perPage: paginationRequest.perPage});
    }
    catch(err){
        console.log(err);
    }
    
}

onBeforeMount(async()=>{
    var matchID = route.params.matchId as string;
    if(!isNaN(parseInt(matchID)))
        matchId.value = parseInt(matchID);
    await getData();
})
</script>