<template>
    <div class="container my-5 adminbo">

        <div class="row">
            <div class="col-2 mb-2">
                <router-link :to="{name: 'admin_index' }">CLASSIFICA UTENTI</router-link>
            </div>
            
            
            <div class="col-3  mb-2">
                <router-link :to="{name: 'admin_missions_no_match' }">STORICO DETTAGLI MATCHES</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <h2 class="text-center mb-4">Anagrafiche</h2>
            </div>
            <div class="col-4 text-right mb-2">
                <button @click="downloadCsv" type="button" class="btn btn-primary">Estrai CSV Dettagli</button>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
                <thead class="table-light">
                    <tr>
                        <th>ID Utente</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Matches Ids</th>
                        <th>Completate</th>
                        <th>Non Completate</th>
                        <th>Punteggio Max</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="el in data.usersMatchesViews" :key="el.appUser?.id">
                        <tr>
                            <td>{{ el.userId }}</td>
                            <td>{{ el.originalUserName}}</td>
                            <td>{{ el.userEmail }}</td>
                            <td>
                                <template v-for="(matchId, index) in el.userMatchsIds" :key="matchId">
                                    <router-link :to="{name: 'admin_missions', params: {matchId: matchId}}">{{ matchId }}</router-link>
                                    <span v-if="index < el.userMatchsIds!.length - 1">, </span>
                                </template>
                            </td>
                            <td>{{ el.userMatchesCompleted }}</td>
                            <td>{{ el.userMatchesNotCompleted }}</td>
                            <td>{{ el.userMatchsMaxScore }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="row">
                <div class="col">
                    <template v-if="pages > 1">
                        <page-nav-component
                            :per_page="paginationRequest.perPage ?? 2"
                            :total_items="data.totalResults ?? 0"
                            :number_of_pages="pages"
                            :current_page="paginationRequest.page ?? 1"
                            @go-to-page="goToPage"
                            @next="nextPage"
                            @previous="previous"
                        ></page-nav-component>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import PageNavComponent from '@/components/Navbars/PageNavComponent.vue';
import { CreateAxiosInstance } from '@/services/auth.module';
import { AdminService, PaginationRequest, UserMatches, serviceOptions } from '@/services/swagger';
import store from '@/store';
import axios from 'axios';
import { computed, onBeforeMount, reactive, ref } from 'vue';

serviceOptions.axios = CreateAxiosInstance();

const paginationRequest = reactive<PaginationRequest>(new PaginationRequest({page: 1, perPage: 10}));
const data = ref<UserMatches>(new UserMatches)
const pages = computed(()=>{
    if(data.value.totalResults && paginationRequest.perPage)
        return Math.ceil(data.value.totalResults / paginationRequest.perPage)
    else 
        return 0;
})
const downloadCsv = async () => {
        
    try {
        const WebServicesURL = process.env.VUE_APP_WEBSERVICES_URL;
        const response = await axios.get(WebServicesURL + '/api/Admin/dwl-matches-details', {
            responseType: 'blob', // Specifica che la risposta è un blob,
            headers: {
                'Authorization': `Bearer ${store.getters['UserModule/getUserToken']}`,
                'Content-Type': 'application/json'
            }
        });

        // Crea un URL per il blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Crea un elemento <a> per avviare il download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'details_matches.csv');
        document.body.appendChild(link);
        link.click();

        // Rimuovi l'elemento <a> e rilascia l'URL del blob
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading CSV:', error);
      }
}

const getData = async () => {
    data.value = await AdminService.matchesHistory({page: paginationRequest.page, perPage: paginationRequest.perPage})
}
const goToPage = async (page: number) => {
    paginationRequest.page = page;
    await getData();
}
const nextPage = async () => {
    if(paginationRequest.page)
        paginationRequest.page++
    await getData();
}

const previous = async () => {
    if(paginationRequest.page)
        paginationRequest.page--
    await getData();
}

onBeforeMount(async()=>{
    await getData();
})
</script>