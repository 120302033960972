<template>
    <template v-if="!isLoaded">
        <div></div>
    </template>
    <template v-else>
        <DefaultQuestionLayout
            :mission-name="missionType.buildName"
            :background-image="question.fileName"
            @on-go-ahead-action="goAhead"
            @on-go-back-action="()=>router.push({name:'map'})"
            @on-cancel-action="onCancel"
        >
            <template v-slot:question-content>
                <component :key="refreshCom" :is="currentLayoutComponent" v-bind="layoutProps" @OnDropAssociation="OnDropAssociation"></component>
            </template>
        </DefaultQuestionLayout>
    </template>
</template>

<script setup lang="ts">

import { useRoute } from 'vue-router';
import { MissionType, Question, MissionService, LayoutType, serviceOptions, Answer, ComparationAnswer, QuestionService, CloseQuestionRequest } from '@/services/swagger';
import DefaultQuestionLayout from './DefaultQuestionLayout.vue';
import {watch, ref, Component, onBeforeMount, markRaw} from 'vue';
import { CreateAxiosInstance } from '@/services/auth.module';
import { Association } from '@/composition/UseDragAndDrop';
import store from '@/store';
import router from '@/router';
import { AxiosError } from 'axios';
const refreshCom = ref(0);
const question_association= ref<Association[][]>([]);
const route = useRoute();
const isLoaded = ref(false);
let currentLayoutComponent:Component;
const layoutProps = ref();
const missionType = ref<MissionType>(new MissionType({buildName: ""}));
const missionTypeId = ref(route.params.missionTypeId as string)
const question = ref<Question>({});
const answer = ref<Answer[]>([]);
const comparizationAnswer = ref<ComparationAnswer[]>([]);
serviceOptions.axios = CreateAxiosInstance();
const onCancel = async () => {
    store.commit("QuestionAssociationModule/cancelAssociations");
    await GetData(missionTypeId.value); 
    refreshCom.value++
}
const GetComponentForLayout = async (type:LayoutType) => {
    switch (type) {
        case 0:{
            const CancelLayoutComponent = await import('@/layouts/CancelLayout.vue')
            return {
                component: CancelLayoutComponent.default, 
                props:{labelsToMove: answer.value, labelsFixed: comparizationAnswer.value, question: question.value}
            }
        }
        case 1:{
            const LineLayoutComponent = await import('@/layouts/LineLayout.vue')
            return {
                component: LineLayoutComponent.default,
                props:{labelsToMove: answer.value, labelsFixed: comparizationAnswer.value, question: question.value}
            }
        }
        case 2:{
            const SquaresLayout = await import('@/layouts/SquaresLayout.vue')
            return {
                component: SquaresLayout.default,
                props:{labelsToMove: answer.value, labelsFixed: comparizationAnswer.value, question: question.value}
            }
        }
        default:{
            const BlankLayout = await import('@/layouts/BlankLayout.vue')
            return {
                component: BlankLayout.default,
                props:{labelsToMove: answer.value, labelsFixed: comparizationAnswer.value, question: question.value}
            }
        }
    }
}
//Ottieni la prima domanda della missione
const GetData = async(missionTypeID:string) =>{
    let mission_type_id = parseInt(missionTypeID as string)
    try{
        var res = await MissionService.question({missionTypeId: mission_type_id});
        if(!res || !res.missionType || res.missionType == null)
        {
            router.push({name:'map'})
        }
        missionType.value = res.missionType as MissionType;
        question.value = res.question as Question;
        answer.value = res.answers as Answer[];
        comparizationAnswer.value = res.comparationAnswers as ComparationAnswer[];
        const {component, props} = await GetComponentForLayout(res.layoutEffect?.type as LayoutType)
        currentLayoutComponent = markRaw(component);
        layoutProps.value = props;
        store.commit("QuestionAssociationModule/updateQuestionId", res.question?.id);
        refreshCom.value++;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }catch(error:any){
        console.log(error);
        if(error.response.status == 408){
            confirm(error.response.data);
            await GetData(missionTypeID);
        }
    }
    isLoaded.value = true;
}

//Ottieni la prossima domanda
const getNextQuestion = async () => {
    const request:CloseQuestionRequest = store.getters["QuestionAssociationModule/getCloseQuestionRequest"];
    request.missionTypeId = missionType.value.id;

    try{
        const res = await QuestionService.next({body: request});
        if(!res){
            router.push({name:"map"})
            return;
        }
        missionType.value = res.missionType as MissionType;
        question.value = res.question as Question;
        answer.value = res.answers as Answer[];
        comparizationAnswer.value = res.comparationAnswers as ComparationAnswer[];
        const {component, props} = await GetComponentForLayout(res.layoutEffect?.type as LayoutType)
        currentLayoutComponent = markRaw(component);
        layoutProps.value = props;
        store.commit("QuestionAssociationModule/updateQuestionId", res.question?.id);
        refreshCom.value++;
    }
    catch(err:unknown){
       if(err instanceof AxiosError){
        if(err.response?.status == 408){
            confirm(err.response.data);
            await GetData(missionTypeId.value); 
        }
       }
    }
}

const goAhead = async() => {
    await getNextQuestion();
}

const OnDropAssociation = (associations:Association[]) =>{
    if(!question.value.id) return;
    question_association.value[question.value.id] = associations;
}
onBeforeMount(async() => {
    await GetData(missionTypeId.value); 
    serviceOptions.axios = CreateAxiosInstance();
});

watch(missionTypeId, async(newMissionTypeId, oldMissionTypeId)=>{
    if(newMissionTypeId != oldMissionTypeId){
        console.log(newMissionTypeId);
        await GetData(newMissionTypeId);
    }
})
</script>