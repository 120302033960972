<template>
    <!-- div>
        <pre>
        </pre>
        <table class="table table-dark">
            <thead>
                <tr>
                <th scope="col">Posizione</th>
                <th scope="col">Nick Name</th>
                <th scope="col">Nome Completo</th>
                <th scope="col">Punteggio</th>
                <th scope="col">Arrotondamento</th>
                </tr>
            </thead>
            <tbody>
                    <template v-for="score in usersScores" :key="score.matchId">
                        <tr scope="row" @click="goToMissionsPage(score.matchId)">
                            <th>{{ score.position }}</th>
                            <th>{{ score.name }}</th>
                            <th>{{ score.originalName }}</th>
                            <th>{{ score.score }}</th>
                            <th>{{ score.totalScore }}</th>
                        </tr>
                    </template>
            </tbody>
        </table>
        <template v-if="pages > 1">
            <page-nav-component
                :number_of_pages="pages"
                @go-to-page="goToPage"
                @next="nextPage"
                @previous="previous"
            ></page-nav-component>
        </template>
    </div -->
    <div class="container my-5 adminbo adminbohome">

        <div class="row mb-2">
            <div class="col-2 mb-2">
                <router-link :to="{name: 'admin_registry' }">ANAGRAFICHE</router-link>
            </div>
            
            
            <div class="col-3  mb-2">
                <router-link :to="{name: 'admin_missions_no_match' }">STORICO DETTAGLI MATCHES</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <h2 class="text-center mb-4">Classifica Utenti</h2>
            </div>
            <div class="col-4 text-right mb-2">
                <button @click="downloadCsv" type="button" class="btn btn-primary">Estrai CSV classifica</button>
            </div>
        </div>



        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
                <thead class="table-light">
                    <tr>
                        <th>Posizione</th>
                        <th>Match ID</th>
                        <th>ID Utente</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Punteggio Totale</th>
						<th>Durata in millisecondi</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="score in usersScores" :key="score.matchId">
                        <tr scope="row">
                            <td>{{ score.position }}</td>
                            <td><router-link :to="{ name: 'admin_missions', params: {matchId: score.matchGame?.id }}">{{ score.matchId }}</router-link></td>
                            <td>{{ score.matchGame?.appUserID }}</td>
                            <td>{{ score.matchGame?.appUser?.originalName }}</td>
                            <td>{{ score.matchGame?.appUser?.email }}</td>
                            <td>{{ score.matchGame?.totalScore }}</td>
                            <td>{{ score.matchGame?.matchTime }}</td>
                        </tr>
                    </template>
                    
                    <!-- Aggiungi altre righe se necessario -->
                </tbody>
            </table>
        </div>
        <template v-if="pages > 1">
            <page-nav-component
                :per_page="paginationRequest.perPage ?? 0"
                :total_items="results.totalResults ?? 0"
                :current_page="paginationRequest.page ?? 1"
                @go-to-page="goToPage"
                @next="nextPage"
                @previous="previous"
            ></page-nav-component>
        </template>
    </div>
</template>

<script setup lang="ts">

import PageNavComponent from '@/components/Navbars/PageNavComponent.vue';
import { MatchService, PaginationRequest, Ranking, UserScore } from '@/services/swagger';
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { CreateAxiosInstance } from '@/services/auth.module';
import { serviceOptions } from '@/services/swagger';
import axios from 'axios';
import store from '@/store';

serviceOptions.axios = CreateAxiosInstance();
var paginationRequest = reactive<PaginationRequest>(new PaginationRequest({page: 1, perPage: 10}));
var results = ref<Ranking>(new Ranking);
const usersScores = ref<UserScore[]>([]);
const goToPage = async (page: number) => {
    paginationRequest.page = page;
    await getRanking();
}
//const goToMissionsPage = async(matchId)
const pages = computed(()=>{
    if(results.value.totalResults && paginationRequest.perPage)
        return Math.ceil(results.value.totalResults / paginationRequest.perPage)
    else 
        return 0;
})
const nextPage = async () => {
    if(paginationRequest.page)
        paginationRequest.page++
    await getRanking();
}

const previous = async () => {
    if(paginationRequest.page)
        paginationRequest.page--
    await getRanking();
}

const getRanking = async() => {
    results.value = await MatchService.score({body: paginationRequest})
    usersScores.value = results.value.usersScores as UserScore[];
}

const downloadCsv = async () => {
    
    try {
        const WebServicesURL = process.env.VUE_APP_WEBSERVICES_URL;
        const response = await axios.get(WebServicesURL + '/api/Admin/download-ranking', {
            responseType: 'blob', // Specifica che la risposta è un blob,
            headers: {
                'Authorization': `Bearer ${store.getters['UserModule/getUserToken']}`,
                'Content-Type': 'application/json'
            }
        });

        // Crea un URL per il blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Crea un elemento <a> per avviare il download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ranking.csv');
        document.body.appendChild(link);
        link.click();

        // Rimuovi l'elemento <a> e rilascia l'URL del blob
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading CSV:', error);
        alert('Error downloading CSV:');
      }
    
}

onBeforeMount(async ()=> {
    getRanking();
})

</script>