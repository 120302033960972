import Vuex, {StoreOptions} from 'vuex';
import { RootState } from './types';
import createPersistentState from 'vuex-persistedstate'
import UserModule from './UserModule';
import QuestionAssociationModule from './QuestionAssociationModule.';
const store: StoreOptions<RootState> = {
    state: {
      // La radice dello stato dell'app
      version: '1.0.0', // Esempio di stato nella radice
    },
    modules: {
        UserModule,
        QuestionAssociationModule
    },
    plugins:[createPersistentState()]
  };
  
  export default new Vuex.Store<RootState>(store);