<template>
    <button
        v-bind="$attrs"
        class="btn mb-0"
        :class="getClasses(variant, color, size, fullWidth, active)"
    >
      <slot />
    </button>
  </template>
  
<script setup lang="ts">
import {defineProps, withDefaults} from 'vue';

withDefaults(defineProps<{
    color?:string,
    size?:string,
    variant?:string,
    fullWidth?:boolean,
    active?:boolean
}>(),{
    color: "succes",
    size: "md",
    variant: "fill",
    fullWidth: false,
    active: false
})
const getClasses = (variant:string, color:string, size:string, fullWidth:boolean, active:boolean): string => {
    let colorValue, sizeValue, fullWidthValue, activeValue;

    // Setting the button variant and color
    if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
    } else if (variant === "outline") {
        colorValue = `btn-outline-${color}`;
    } else {
        colorValue = `btn-${color}`;
    }

    sizeValue = size ? `btn-${size}` : null;

    fullWidthValue = fullWidth ? `w-100` : null;

    activeValue = active ? `active` : null;

    return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
}
</script>
  