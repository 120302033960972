<template>
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="fakeStore.state.isRTL ? 'overflow-x: hidden' : ''">
    <router-view/>
    <app-footer></app-footer>
  </main>
</template>
<script setup lang="ts">
import AppFooter from './components/Footers/AppFooter.vue';
import { reactive } from 'vue';

const fakeStore = reactive({
  state: {
    isRTL: false
  }
})


</script>
