<template>
    <div class="bodypage log-pref">
      <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div class="display-logpref  mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <div class="headlogo">
                      <div class="row">
                      <div class="col head">
                        <h1>Scegli il tuo avatar per iniziare a giocare</h1>
                      </div>
                      <div class="col head-logo">
                        <img src="@/assets/img/logo_rev.svg" alt="Notaropoly" class="logo-image">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <form role="form" class="text-start">
                      <div class="row">
                        <div class="col-4">
                          <label>Nome</label>
                        </div>
                        <div class="col-8">
                          <input v-model="preferencesModel.nome"  placeholder="edit me" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-4">
                              <label>Avatar</label>
                            </div>
                            <div class="col-8">
                                <select-image
                                :selected-id="preferencesModel.avatarId"
                                @select-avatar="($event) => preferencesModel.avatarId = $event?.id" 
                                :avatars="preferencesFormData.avartars"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4"><label>Colore Preferito</label></div>
                        <div class="col-8">
                          <select-color 
                            :selected-color="preferencesModel.color"
                            :colors="preferencesFormData.colors"
                            @select-color-event="($event)=> preferencesModel.color = $event "
                          />
                        </div>
                      </div>
                      <div class="text-center">
                        <soft-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          full-width
                          @click="UpdatePreferences"
                          role="link"
                          >GIOCA
                        </soft-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
  </template>
<script setup lang="ts">
import {ref, onMounted, reactive} from 'vue'
import store from '@/store';
import router from '@/router';
import SoftButton from '@/components/Buttons/SoftButton.vue';
import SelectImage from '@/components/Inputs/SelectImage.vue';
import SelectColor from '@/components/Inputs/SelectColor.vue';
import { Color, PreferenceUserModelRequest, PreferencesFormDataResponse, PreferencesService, serviceOptions } from '@/services/swagger';
import { CreateAxiosInstance } from '@/services/auth.module';
const preferencesFormData = ref<PreferencesFormDataResponse>({});
var preferencesModel = reactive<PreferenceUserModelRequest>(new PreferenceUserModelRequest());
const axios = CreateAxiosInstance();
serviceOptions.axios = axios;

const UpdatePreferences = async () =>{
  const selectedAvatar = preferencesFormData.value.avartars?.find(a => a.id == preferencesModel.avatarId)
      
  await PreferencesService.preferences1({body:preferencesModel})
    .then(()=>{
      store.commit("UserModule/updateUserPreferences", {nome: preferencesModel.nome, avatar: selectedAvatar, color: preferencesModel.color})
      router.push({name: "map"})
    })
    .catch(err=>{
      console.log(err);
    })
}

onMounted(async () => {
  var userPreferences:PreferenceUserModelRequest = store.getters["UserModule/getUserPreferences"];
  await PreferencesService.preferences()
    .then(res=>{
      preferencesFormData.value.colors = res.colors;
      preferencesFormData.value.avartars = res.avartars;
      preferencesModel.avatarId = userPreferences.avatarId;
      preferencesModel.color = userPreferences.color;
      preferencesModel.nome = userPreferences.nome;
      if(!preferencesModel.avatarId)
      {
        res.avartars?.forEach((a, i) => {
          if(i == 0){
            preferencesModel.avatarId = a.id
          }
        });
      }
      if(!preferencesModel.color?.hashCode)
      {
        res.colors?.forEach((c, i) => {
          if(i == 0){
            preferencesModel.color = new Color({hashCode: c.hashCode})
          }
        });
      }
      preferencesModel.nome = res.name;
    }).catch(err=>{
      console.log(err);
    })
})

</script>
<style scoped>

.bodypage {
    background-image: url("@/assets/img/fondo.jpg");
    background-color: #0b4d86!important;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  
  .main-content{
  background-color: transparent!important;
}
  .display-logpref {
    width: 90%;
    margin: 0 auto!important;
}

.container {
    padding: 2em;
    text-align: center;
    width: 100%;
    max-width: none;
}
.display-logpref .card {
    background-color: #04213c87;
}

.head-logo img.logo-image {max-height: 70px;float: right;}

.head h1 {
    color: white;
    font-weight: bold;
}

.card-header p {
    color: #014482;
    font-weight: bold;
    padding-bottom: 1em;
}
.card-header{
  background-color: #ffffff88;
  padding: 1em 2em;
}

.card-body{
  padding: 1em 2em;
}

.card-body label {
    width: 100%;
    color: white;
    font-size: 1.5em;
}

.card-body input {
    width: 100%;
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
    border: 1px;
    background-color: #ffffff88;
    color: #333333;
}



button.btn {
    background-color: #3498db!important;
    width: auto!important;
    padding: 0.25em 2em;
    font-size: 1.5em;
}

.Soft-Select-Color {min-height: auto;}

.display-logpref .card {
    min-height: calc(100vh - 4em);
}


form.text-start > .row {
    border-bottom: 1px solid #898989;
    padding: 1em 0;
}

.headlogo .head h1 {
    color: #014482;
    font-size: 2em;
}

@media only screen and (max-width: 600px) {
  .bodypage {
      font-size: 2em !important;
  }
  .head-logo img.logo-image {
    max-width: 100%;
}

}

</style>