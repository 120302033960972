import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import QuestionLayoutVue from '@/layouts/QuestionLayout.vue';
import LoginViewVue from '@/views/LoginView.vue';
import LoginPrefViewVue from '@/views/LoginPrefView.vue';
import MapViewVue from '@/views/MapView.vue';
import PageView401Vue from '@/views/PageView401.vue';
import IndexPageVue from '@/views/IndexPage.vue';
import Ranking from '@/views/RankingView.vue';
import EndGame from '@/views/EndGameView.vue';
import BlankLayoutVue from '@/layouts/BlankLayout.vue';
import LoginAdmin from '@/views/LoginAdmin.vue';
import AdminIndexView from '@/views/AdminIndexView.vue';
import store from '@/store';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import PageForbidden403 from '@/views/PageForbidden403.vue';
import AdminMissionsView from '@/views/back_office/AdminMissionsView.vue';
import AdminMatchesView from '@/views/back_office/AdminMatchesView.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: "/static_page",
    name: "static_page",
    component: LoginLayout
  },
  {
    path: "/pages",
    component: BlankLayoutVue,
    children: [
      {
        path: "page401",
        name: "401",
        component: PageView401Vue
      },
      {
        path: "page403",
        name: "Forbidden",
        component: PageForbidden403
      }
  ]
  },
  {
    path: "/",
    //component: LoginViewVue,
    redirect: "/login"
  },
  {
    path: "/indexPage",
    name: "index",
    component: IndexPageVue
  },
  {
    path: "/login",
    name: "login",
    component: LoginViewVue,
  },
  /*
  {
    path: "/devlogin",
    name: "devlogin",
    component: DevLoginVue 
  },
  */
  {
    path: "/ranking",
    name: "ranking",
    component: Ranking 
  },
  {
    path: "/endgame",
    name: "endgame",
    component: EndGame 
  },
  {
    path: "/preference",
    name: "preferences",
    component: LoginPrefViewVue
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/login",
    component: BlankLayoutVue,
    meta: { adminSection: true },
    children: [
      {
        path: "login",
        name: "admin_login",
        component: LoginAdmin
      },
      {
        path: "admin_index",
        name: "admin_index",
        component: AdminIndexView,
        meta: { requiresAuth: true, roles: ['Admin'], adminSection: true }
      },
      
      {
        path: "admin_missions/:matchId",
        name: "admin_missions",
        component: AdminMissionsView,
        meta: { requiresAuth: true, roles: ['Admin'], adminSection: true }
      },
      {
        path: "admin_missions",
        name: "admin_missions_no_match",
        component: AdminMissionsView,
        meta: { requiresAuth: true, roles: ['Admin'], adminSection: true }
      },
      {
        path: "admin_registry",
        name: "admin_registry",
        component: AdminMatchesView,
        meta: { requiresAuth: true, roles: ['Admin'], adminSection: true }
      }
    ]
  },
  {
    path: "/mission",
    name: "mission",
    children: [
      {
        path: "/mission/map",
        name: "map",
        component: MapViewVue,
      },
    ]
  },
  {
    path: '/mission/:missionTypeId',
    name: 'mission_type',
    component: QuestionLayoutVue
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})



interface MyJwtPayload extends JwtPayload {
  role:string[]
}
function getUserRoleFromToken(token:string) {
  try {
    const decodedToken : MyJwtPayload = jwtDecode(token);
    return decodedToken.role; // Assumi che la claim per il ruolo sia 'role'
  } catch (error) {
    console.error('Errore nella decodifica del token:', error);
    return null;
  }
}
function redirectToHttps() {
  if (location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
}
}
router.beforeEach((to, from, next) => {
  
  // Verifica se la rotta corrente o una delle sue rotte padre ha il meta dato adminSection
  const isAdminSection = to.matched.some(record => record.meta.adminSection);
  const isNotRankingPage = to.name != "ranking";
  const isNotIndexPage = to.name != "index";
  const isNotStaticPage = to.name != 'static_page';
  if(isAfterTargetDateTime() && !isAdminSection && isNotIndexPage){
    alert(to.name);
    if(isNotStaticPage && isNotRankingPage){
      store.commit("UserModule/clearSession")
      router.push({name: "static_page"});
    }
  }
  const roles = to.meta.roles as string[];
  if(to.meta.requiresAuth && roles.includes("Admin"))
  {
    const userRole = getUserRoleFromToken(store.getters['UserModule/getUserToken']);
    if(userRole == null)
      router.push("/");
    else if(!userRole.includes("Admin"))
      router.push("/");
  }
  //redirectToHttps();

  next();
})
export const isAfterTargetDateTime = () => {
  const targetDate = '2024-10-16';
  const targetTime = '23:59';
  // Ottieni la data e l'ora corrente
  const currentDate = new Date();

  // Crea un oggetto Date per la data e l'ora target
  const targetDateTime = new Date(`${targetDate}T${targetTime}:00`);

  // Confronta la data e l'ora corrente con la data e l'ora target
  return currentDate > targetDateTime;
}
export default router
