/** Generate by swagger-axios-codegen */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */
import axiosStatic, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /**
   * show loading status
   */
  loading?: boolean;
  /**
   * display error message
   */
  showError?: boolean;
  /**
   * data security, extended fields are encrypted using the specified algorithm
   */
  security?: Record<string, 'md5' | 'sha1' | 'aes' | 'des'>;
  /**
   * indicates whether Authorization credentials are required for the request
   * @default true
   */
  withAuthorization?: boolean;
}

export interface IRequestPromise<T = any> extends Promise<IRequestResponse<T>> {}

export interface IRequestResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
}

export interface IRequestInstance {
  (config: any): IRequestPromise;
  (url: string, config?: any): IRequestPromise;
  request<T = any>(config: any): IRequestPromise<T>;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: IRequestInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AdminService {
  /**
   *
   */
  static admin(
    params: {
      /** requestBody */
      body?: Admin;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Admin> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadRanking(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin/download-ranking';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missions(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Mission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin/missions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static history(
    params: {
      /**  */
      id?: number;
      /**  */
      page?: number;
      /**  */
      perPage?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HistoryAggregates> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin/history';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], page: params['page'], perPage: params['perPage'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static matchesHistory(
    params: {
      /**  */
      page?: number;
      /**  */
      perPage?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserMatches> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin/matches-history';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], perPage: params['perPage'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static dwlMatchesDetails(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin/dwl-matches-details';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static dwlDetails(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Admin/dwl_details';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   *
   */
  static secret(options: IRequestOptions = {}): Promise<ExternalAuthLoginInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Auth/secret';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Auth/login';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /** requestBody */
      body?: RegisterAppUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Auth/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static auth(options: IRequestOptions = {}): Promise<AppUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Auth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Auth/logout';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class MatchService {
  /**
   *
   */
  static new(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Match/new';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static score(
    params: {
      /** requestBody */
      body?: PaginationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Ranking> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Match/score';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static endGame(options: IRequestOptions = {}): Promise<UserScore> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Match/end_game';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class MissionService {
  /**
   *
   */
  static question(
    params: {
      /**  */
      missionTypeId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuestionCompleteDataModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mission/{missionTypeId}/question';
      url = url.replace('{missionTypeId}', params['missionTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missions(options: IRequestOptions = {}): Promise<AvaiableMissionType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mission/missions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class PreferencesService {
  /**
   *
   */
  static preferences(options: IRequestOptions = {}): Promise<PreferencesFormDataResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Preferences';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static preferences1(
    params: {
      /** requestBody */
      body?: PreferenceUserModelRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Preferences';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class QuestionService {
  /**
   *
   */
  static next(
    params: {
      /** requestBody */
      body?: CloseQuestionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuestionCompleteDataModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Question/next';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class Admin {
  /**  */
  'id'?: number;

  /**  */
  'userName'?: string;

  /**  */
  'password'?: string;

  constructor(data: Admin = {}) {
    Object.assign(this, data);
  }
}

export class Answer {
  /**  */
  'id'?: number;

  /**  */
  'text'?: string;

  /**  */
  'score'?: number;

  /**  */
  'questionAnswerComparizationAnswer'?: QuestionAnswerComparizationAnswer[];

  constructor(data: Answer = {}) {
    Object.assign(this, data);
  }
}

export class AppUser {
  /**  */
  'id'?: number;

  /**  */
  'originalName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'email'?: string;

  /**  */
  'hashCode'?: string;

  /**  */
  'color'?: Color;

  /**  */
  'avatarID'?: number;

  /**  */
  'avatar'?: Avatar;

  constructor(data: AppUser = {}) {
    Object.assign(this, data);
  }
}

export class Association {
  /**  */
  'answerId'?: number;

  /**  */
  'associationAnswerId'?: number;

  constructor(data: Association = {}) {
    Object.assign(this, data);
  }
}

export class AvaiableMissionType {
  /**  */
  'missionType'?: MissionType;

  /**  */
  'isClosed'?: boolean;

  constructor(data: AvaiableMissionType = {}) {
    Object.assign(this, data);
  }
}

export class Avatar {
  /**  */
  'id'?: number;

  /**  */
  'fileName'?: string;

  constructor(data: Avatar = {}) {
    Object.assign(this, data);
  }
}

export class CloseQuestionRequest {
  /**  */
  'questionId'?: number;

  /**  */
  'missionTypeId'?: number;

  /**  */
  'associations'?: Association[];

  constructor(data: CloseQuestionRequest = {}) {
    Object.assign(this, data);
  }
}

export class Color {
  /**  */
  'hashCode'?: string;

  /**  */
  'users'?: AppUser[];

  constructor(data: Color = {}) {
    Object.assign(this, data);
  }
}

export class ComparationAnswer {
  /**  */
  'id'?: number;

  /**  */
  'text'?: string;

  /**  */
  'questionAnswerComparizationAnswer'?: QuestionAnswerComparizationAnswer[];

  constructor(data: ComparationAnswer = {}) {
    Object.assign(this, data);
  }
}

export class ExternalAuthLoginInfoResponse {
  /**  */
  'externalProviderAuthUrl'?: string;

  /**  */
  'method'?: string;

  constructor(data: ExternalAuthLoginInfoResponse = {}) {
    Object.assign(this, data);
  }
}

export class HistoryAggregates {
  /**  */
  'totalResults'?: number;

  /**  */
  'userDetailMatchResponse'?: UserDetailMatchResponse[];

  /**  */
  'questionHystories'?: QuestionHystory[];

  constructor(data: HistoryAggregates = {}) {
    Object.assign(this, data);
  }
}

export class LayoutEffect {
  /**  */
  'type'?: LayoutType;

  /**  */
  'questions'?: Question[];

  constructor(data: LayoutEffect = {}) {
    Object.assign(this, data);
  }
}

export class MatchGame {
  /**  */
  'id'?: number;

  /**  */
  'totalScore'?: number;

  /**  */
  'matchTime'?: number;

  /**  */
  'appUserID'?: number;

  /**  */
  'appUser'?: AppUser;

  /**  */
  'missions'?: Mission[];

  constructor(data: MatchGame = {}) {
    Object.assign(this, data);
  }
}

export class Mission {
  /**  */
  'id'?: number;

  /**  */
  'partialScore'?: number;

  /**  */
  'partialTime'?: number;

  /**  */
  'startDate'?: Date;

  /**  */
  'endDate'?: Date;

  /**  */
  'matchGameID'?: number;

  /**  */
  'missionTypeID'?: number;

  /**  */
  'matchGame'?: MatchGame;

  /**  */
  'missionType'?: MissionType;

  /**  */
  'questionHistory'?: QuestionHystory[];

  constructor(data: Mission = {}) {
    Object.assign(this, data);
  }
}

export class MissionType {
  /**  */
  'id'?: number;

  /**  */
  'buildName'?: string;

  /**  */
  'priority'?: number;

  /**  */
  'questions'?: Question[];

  constructor(data: MissionType = {}) {
    Object.assign(this, data);
  }
}

export class PaginationRequest {
  /**  */
  'perPage'?: number;

  /**  */
  'page'?: number;

  constructor(data: PaginationRequest = {}) {
    Object.assign(this, data);
  }
}

export class PreferenceUserModelRequest {
  /**  */
  'avatarId'?: number;

  /**  */
  'nome'?: string;

  /**  */
  'color'?: Color;

  constructor(data: PreferenceUserModelRequest = {}) {
    Object.assign(this, data);
  }
}

export class PreferencesFormDataResponse {
  /**  */
  'name'?: string;

  /**  */
  'colors'?: Color[];

  /**  */
  'avartars'?: Avatar[];

  constructor(data: PreferencesFormDataResponse = {}) {
    Object.assign(this, data);
  }
}

export class Question {
  /**  */
  'id'?: number;

  /**  */
  'titoletto'?: string;

  /**  */
  'text'?: string;

  /**  */
  'fileName'?: string;

  /**  */
  'missionTypeId'?: number;

  /**  */
  'priority'?: number;

  /**  */
  'missionType'?: MissionType;

  /**  */
  'layoutEffectType'?: LayoutType;

  /**  */
  'layoutEffect'?: LayoutEffect;

  /**  */
  'questionAnswersComparizationAnswers'?: QuestionAnswerComparizationAnswer[];

  /**  */
  'questionHistory'?: QuestionHystory[];

  constructor(data: Question = {}) {
    Object.assign(this, data);
  }
}

export class QuestionAnswerComparizationAnswer {
  /**  */
  'questionId'?: number;

  /**  */
  'answerId'?: number;

  /**  */
  'comparationAnswerId'?: number;

  /**  */
  'question'?: Question;

  /**  */
  'answer'?: Answer;

  /**  */
  'comparationAnswer'?: ComparationAnswer;

  constructor(data: QuestionAnswerComparizationAnswer = {}) {
    Object.assign(this, data);
  }
}

export class QuestionCompleteDataModel {
  /**  */
  'layoutEffect'?: LayoutEffect;

  /**  */
  'missionType'?: MissionType;

  /**  */
  'question'?: Question;

  /**  */
  'answers'?: Answer[];

  /**  */
  'comparationAnswers'?: ComparationAnswer[];

  constructor(data: QuestionCompleteDataModel = {}) {
    Object.assign(this, data);
  }
}

export class QuestionHystory {
  /**  */
  'id'?: number;

  /**  */
  'missionId'?: number;

  /**  */
  'mission'?: Mission;

  /**  */
  'questionId'?: number;

  /**  */
  'question'?: Question;

  /**  */
  'partialScore'?: number;

  /**  */
  'isClosed'?: boolean;

  /**  */
  'jsonAssociations'?: string;

  /**  */
  'timeStamp'?: Date;

  /**  */
  'timeClosedStamp'?: Date;

  /**  */
  'milliseconds'?: number;

  /**  */
  'associations'?: Association[];

  constructor(data: QuestionHystory = {}) {
    Object.assign(this, data);
  }
}

export class Ranking {
  /**  */
  'totalResults'?: number;

  /**  */
  'usersScores'?: UserScore[];

  /**  */
  'matchGames'?: MatchGame[];

  constructor(data: Ranking = {}) {
    Object.assign(this, data);
  }
}

export class RegisterAppUserRequest {
  /**  */
  'id'?: number;

  /**  */
  'nickName'?: string;

  /**  */
  'colorHash'?: string;

  /**  */
  'avatarID'?: number;

  constructor(data: RegisterAppUserRequest = {}) {
    Object.assign(this, data);
  }
}

export class UserDetailMatchResponse {
  /**  */
  'matchId'?: number;

  /**  */
  'idUser'?: number;

  /**  */
  'email'?: string;

  /**  */
  'missionId'?: number;

  /**  */
  'questionText'?: string;

  /**  */
  'questionId'?: number;

  /**  */
  'partialScore'?: number;

  /**  */
  'completed'?: boolean;

  /**  */
  'associations'?: string;

  /**  */
  'dateTime'?: string;

  /**  */
  'closedDateTime'?: string;

  /**  */
  'milliseconds'?: number;

  constructor(data: UserDetailMatchResponse = {}) {
    Object.assign(this, data);
  }
}

export class UserMatches {
  /**  */
  'totalResults'?: number;

  /**  */
  'usersMatchesViews'?: UserMatchesOBJ[];

  constructor(data: UserMatches = {}) {
    Object.assign(this, data);
  }
}

export class UserMatchesOBJ {
  /**  */
  'userId'?: number;

  /**  */
  'originalUserName'?: string;

  /**  */
  'userEmail'?: string;

  /**  */
  'userMatchsIds'?: number[];

  /**  */
  'userMatchsMaxScore'?: number;

  /**  */
  'userMatchesCompleted'?: number;

  /**  */
  'userMatchesNotCompleted'?: number;

  constructor(data: UserMatchesOBJ = {}) {
    Object.assign(this, data);
  }
}

export class UserScore {
  /**  */
  'position'?: number;

  /**  */
  'matchId'?: number;

  /**  */
  'avatarFileName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'userColor'?: string;

  /**  */
  'score'?: number;

  /**  */
  'originalName'?: string;

  /**  */
  'matchGame'?: MatchGame;

  constructor(data: UserScore = {}) {
    Object.assign(this, data);
  }
}

export type LayoutType = 0 | 1 | 2;
