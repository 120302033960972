<template>
<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item" @click="havePreviousPage">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item"><a class="page-link">{{ props.current_page }} di {{ Math.ceil(props.total_items / props.per_page) }}</a></li>

    <li class="page-item" @click="haveNextPage">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>

</template>

<script setup lang="ts">

import { computed, defineProps, defineEmits } from 'vue';

const emits = defineEmits<{
    (e: 'goToPage', pageNum: number): void
    (e: 'next'): void,
    (e: 'previous'): void
}>()

const haveNextPage = () => {
    if(props.current_page && props.current_page < Math.ceil( props.total_items / props.per_page))
    {
      emits('next');
    }
}
const havePreviousPage = () => {
    if(props.current_page && props.current_page > 1)
    {
      emits('previous');
    }
}

const props = defineProps<{
    current_page : number
    per_page : number
    total_items : number
}>();

</script>

<style lang="scss">
.page-item {
  cursor: pointer;
}
</style>