<template>
    <footer class="py-3 footer">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
          <div class="mb-4 col-lg-6 mb-lg-0">
            <div
              class="text-sm text-center copyright text-muted"
              :class="false ? 'text-lg-end' : 'text-lg-start'"
            >
              <!-- Notartel -->
            </div>
          </div>
          
        </div>
      </div>
    </footer>
  </template>
  
  <script setup lang="ts">

  </script>
  