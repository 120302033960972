<template>
    <div class="Soft-Select-Image">
      <div class="vue-select-image">
        <ul class="vue-select-image__wrapper">
          <li v-for="(avatar, index) in avatars" :key="index" class="vue-select-image__item">
            <div
              :class="{ active: selectedImage === avatar || selectedId == avatar.id }"
              class="vue-select-image-item"
              @click="selectImage(avatar.id)"
            >
              <img :src="require('@/assets/img/' + avatar.fileName)" :alt="avatar.fileName" :id="`${avatar.id}`" height="auto" width="auto" class="vue-select-image__img">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
import { Avatar } from '@/services/swagger';
import { ref, defineProps, defineEmits} from 'vue';

const props = defineProps<{
  avatars?:Avatar[],
  selectedId?:number
}>();
const emit = defineEmits<{
  selectAvatar: [avatar?:Avatar]
}>();

const selectedImage = ref<Avatar>();

const selectImage = (avatarId?:number) => {
  if(avatarId != null){
    var selected = props.avatars?.find(a => a.id == avatarId);
    selectedImage.value = selected;
    emit("selectAvatar", selected);
  }
}
</script>
<style scoped>
.Soft-Select-Image .vue-select-image-item img.vue-select-image__img {
  max-width: 150px;
}
.Soft-Select-Image .vue-select-image-item img{
  border: 5px solid transparent;
}
.Soft-Select-Image .vue-select-image-item.active img {
    border-color: white;
}



@media only screen and (max-width: 600px) {
  .Soft-Select-Image li.vue-select-image__item {
      width: 50%;
  }
}
</style>
