import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import store from './store'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import "./assets/css/custom.scss";
//import 'bootstrap-vue/dist/bootstrap-vue.css'
const app = createApp(App);
app.use(VueCookieNext)
.use(store)
.use(router);
app.mount('#app')
