import { AppUser, Avatar, Color } from "@/services/swagger";
import { Module, MutationTree } from "vuex";
import { PreferenceUserModelRequest } from "@/services/swagger";
import router from "@/router";
import { RootState } from "./types";
interface UserModuleState {
    user:AppUser | null,
    token: string | null
}
const mutations: MutationTree<UserModuleState> = {
    updateUserState(state, newData: AppUser) {
        if(state.user == null){
           state.user = new AppUser()
        }
        state.user = newData;
        state.user.avatar = newData.avatar;
        state.user.hashCode = newData.hashCode;
        if(!state.user.color)
            state.user.color = new Color();
        state.user.color.hashCode = newData.hashCode;
    },
    updateUserToken(state, newData: string){
        state.token = newData;
    },
    updateUserPreferences(state, newData:{nome:string, avatar:Avatar, color:Color}){
        if(state.user == null){
           state.user = new AppUser()
        }
        state.user.avatarID = newData.avatar.id
        state.user.color = newData.color;
        state.user.name = newData.nome;
        state.user.avatar = newData.avatar
    },
    clearSession(state){
        state.token = null;
        state.user = null;
    },
    logOut(state){
        state.token = null;
        state.user = null;
        document.cookie = "redirect_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        router.push({name:"login"})
        window.open(process.env.VUE_APP_LOGOUT_URL);
    }
};
const getters = {
    getUserState: (state: UserModuleState) => state.user,
    getUserToken: (state: UserModuleState) => state.token,
    getUserAvatarFileName: (state: UserModuleState) => {
        if(state.user != null)
            if(state.user.avatar != null)
                return state.user.avatar.fileName;
        return "default-avatar-profile.jpg";
    },
    getUserPreferences: (state: UserModuleState) => {
        if(state.user == null) return;
        const preferencesModel:PreferenceUserModelRequest = {
            avatarId: state.user.avatar?.id ?? undefined,
            color: state.user.color ? state.user.color : new Color()
        }
        return preferencesModel
    }
};
const UserModule:Module<UserModuleState, RootState> = {
    namespaced: true,
    state:{
        user: null,
        token: null
    },
    mutations,
    actions:{
        
    },
    getters
}

export default UserModule;