<template>
    <div class="toolbar toolbar-bottom mb-3">
        <button class="btn btn-outline btn-back"  @click="emit('onGoBackAction')"> 
        <svg xmlns="http://www.w3.org/2000/svg" height="10" width="14" viewBox="0 0 448 512">
            <path fill="#ffffff" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
        </svg>
        Vai alla mappa</button>
      
        <button @click="emit('onCancelAction')" class="btn btn-outline btn-undo" >
            Resetta la schermata
        </button> 
        <button class="btn btn-outline btn-go" @click="emit('onGoAheadAction')">Procedi grazie 
        <svg xmlns="http://www.w3.org/2000/svg" height="10" width="14" viewBox="0 0 448 512" style="rotate: 180deg;">
            <path fill="#ffffff" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
        </svg>
        </button>
    </div>
    
    <div class="toolbarfly toolbarfly-onleft">
        <button class="btn"  @click="emit('onGoBackAction')"> 
            <svg xmlns="http://www.w3.org/2000/svg" height="10" width="14" viewBox="0 0 448 512">
                <path fill="#19877c" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
            </svg>
            <h6>Vai alla mappa</h6>
        </button>
    </div>
    <div class="toolbarfly toolbarfly-onright">
        <button class="btn" @click="emit('onGoAheadAction')">
            <svg xmlns="http://www.w3.org/2000/svg" height="10" width="14" viewBox="0 0 448 512" style="rotate: 180deg;">
                <path fill="#19877c" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
            </svg>
            <h6>Finito! Procedi grazie </h6>
        </button>
    </div>  
</template>

<script setup lang="ts">
import {defineEmits} from 'vue';
//const refreshCom = ref(0);
/*
const onCancel = () => {
    store.commit("QuestionAssociationModule/cancelAssociations");
    refreshCom.value++
}
*/
const emit = defineEmits<{
    (e: 'onGoAheadAction'): void
    (e: 'onGoBackAction'): void
    (e: 'onCancelAction'): void
}>();
</script>

<style scoped>
.toolbarfly {
    position: absolute;
    top: 30%;
}

.toolbarfly.toolbarfly-onright {
    right: 10px;
}
.toolbarfly.toolbarfly-onleft {
    left: 10px;
}

.toolbarfly .btn {
    animation: blinker 3s linear infinite;
}

.toolbarfly .btn:hover{
    opacity:1!important;
}

.toolbarfly .btn svg {
    width: 100%;
    height: 50px;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}


button.btn h6 {
    background-color: #19877c;
    /* #43a4ff; */
    border-radius: 0.25em;
    color: white;
    font-weight:bold;
    font-size: 80%;
    padding: 0.5em 1em;
    border: 1px solid white;
}

button.btn.btn-outline {
    background-color: #19877c;
    font-size: 0.9em;
}

button.btn.btn-outline:hover {
    background-color: #0a574f;
}


@media (max-width: 1000px) {
  /* … */
   /*.toolbarfly {display:none} */
   .toolbarfly {top:12.5%}
    .toolbar .btn.btn-go {
        animation: blinker 3s linear infinite;
        background-color: #43a4ff;
        font-size: 150%;
    }
}
</style>