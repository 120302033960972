<template>
    <h1>Attendere Prego redirect della richiesta in corso...</h1>
</template>

<script setup lang="ts">
import {onBeforeMount, onMounted} from 'vue'
import {useCookie} from 'vue-cookie-next';
import store from '@/store';
import router from '@/router';
import { isAfterTargetDateTime } from '@/router';
import { AuthService, serviceOptions } from '@/services/swagger';
import { CreateAxiosInstance } from '@/services/auth.module';
const {getCookie} = useCookie();



const tokenValue = String(getCookie("token"));
const redirect_url = String(getCookie("redirect_url"));
onBeforeMount(()=>{
    store.commit("UserModule/updateUserToken", tokenValue);
})
onMounted(async()=>{
    serviceOptions.axios = CreateAxiosInstance();
    if(tokenValue == "null"){
        router.push({name:"401"})
        return;
    }
    
    if(redirect_url == "null"){
        alert("missing_redirect_url is missing")
    }
    
    await AuthService.auth()
        .then(res=>{
            store.commit("UserModule/updateUserState", res)
            if(!isAfterTargetDateTime()){
                router.push(redirect_url);
            }else{
                router.push({name: 'ranking'})
            }
            
        }).catch(()=>{
            //console.log(err);
        }
    )
        
})

</script>