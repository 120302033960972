<template>
    <div class="city-map">
        <div class="row">
            <!-- Edifici -->
            <template v-for="(build, index) in buildsList" :key="build.missionType?.id">
                <div class="col-12 col-sm-3 col col-ed">
                    <build-component
                        :mission-type="build"
                        :class="'building building0' + (index+1)"
                        :current-build-id="currentBuild?.id"
                        @on-click-mission="proceed"
                    ></build-component>
                    <img :src="require(`@/assets/img/ed0${index+1}.png`)" alt="build" class="build-image">
                    
                </div>
            </template>
        </div>
      <div class="headmap">
        <img src="@/assets/img/logo_rev.svg" alt="Notaropoly" class="logo-image">
      </div>
      <!-- Pulsante Procedi -->
      <div class="row console">
        <div class="col">
            <button class="proceed-button" @click="proceed">Procedi</button>
            <a @click="proceed" class="proceed-area"><div class="proceed-overlay"></div></a>
            <h3>Per giocare segui il percorso sulla mappa</h3>
        </div>
      </div>
        <!-- Città in fondo alla pagina -->
        <img src="@/assets/img/percorso.svg" alt="Percorso" class="mapline-track">
        <!--<img src="@/assets/img/map-line02.png" alt="Città" class="other-mapline other-mapline2">  -->
         <img src="@/assets/img/map-line01.png" alt="Città" class="other-mapline other-mapline1">
        <img src="@/assets/img/bgcity.png" alt="Città" class="city-image">
        <img src="@/assets/img/other-ed.png" alt="Città" class="other-image">
        <img src="@/assets/img/other-ed.png" alt="Città" class="other-image other-image2">
        <img src="@/assets/img/other-ed.png" alt="Città" class="other-mapline other-mapline0">
        
        <avatar-component />

    </div>
</template>
  
<script setup lang="ts">
import { AvaiableMissionType, MissionService, MissionType, serviceOptions } from '@/services/swagger';
import {ref, onBeforeMount} from 'vue';
import BuildComponent from '@/components/BuildComponent.vue';
import router from '@/router';
import { CreateAxiosInstance } from '@/services/auth.module';
import AvatarComponent from '@/components/Partials/AvatarComponent.vue';

serviceOptions.axios = CreateAxiosInstance();
const buildsList = ref<AvaiableMissionType[]>([]);
const currentBuild = ref<MissionType>();
const GetBuildsList = async () => {
    try{
        var list = await MissionService.missions();
        buildsList.value = list;
        //I DAti tornano in ordine crescente questo metodo torna il primo risultato utile in ordine crescente
        var last = list.find(m => m.isClosed == false);
        currentBuild.value = last?.missionType;
        //rederict alla pagina hai vinto se tutti gli edifici sono stati completati
        if(!last){
            router.push({name:"endgame"})
        }
    }catch(error){
        console.log(error);
    }
}
const proceed = async () => {
    if(currentBuild.value != null && currentBuild.value.id != null){
       router.push({name:"mission_type", params:{missionTypeId: currentBuild.value.id}})
    }
    else{
        alert("nessun edificio disponibile")
    }
}

onBeforeMount(async()=>{
    await GetBuildsList();
})

</script>
  
<style scoped>
.city-map .city-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 45 / 32;
    border-radius: 1em;
}


.city-map .building .etichetta span.name {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 19%, rgba(0, 0, 0, 0.5) 82%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 1) 100%);
    text-align: center;
    color: white;
}

.city-map .col:nth-child(odd) img.build-image {
    bottom: 0;
}

.city-map .col:nth-child(even) img.build-image {
    top: 0; 
}

.city-map .col img.build-image{
    position: absolute;
    width: 100%;
 }

.city-map .building-labelspace .etichetta {
    position: absolute;
    left: 0;
    top: 0;
}

.city-map .building {
    width: 100%;
    top: 50%;
    position: relative;
    background-color: transparent;
}

.city-map .col {
    position: relative;
    margin: 0!important;
    padding: 0!important;
    height: 100%!important;
    vertical-align: middle!important;
    z-index: 1;
}

.city-map .row {
    position: relative;
    width: 100%;
    margin: 0!important;
    padding: 0!important;
    height: 100%;
}

.city-map .building-labelspace {
    text-align: center;
    margin-top: 50%;
}

.city-map .building {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    z-index: 1;
    font-size:2em;
    border: 0;
}

.city-map .col .building:nth-child(odd) {
     margin-top: -4em;
}
.city-map .col .building:nth-child(even) {
    margin-top: 4em;
}

.headmap {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff55;
    width: 100%;
    padding: 1em 10%;
    text-align: right;
    z-index: 1;
}

.headmap img.logo-image {
    height: 100px;
}



.city-map .row.console button.proceed-button {
    bottom: auto;
    top: 50%;
    transform: translateX(-50%);
    font-size: 2.5em;
    box-shadow: 5px 5px 10px #00000055;
    border-radius: 0.5em;
    padding: 5px 20px;
    animation: blink 1s linear infinite;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.city-map  button.proceed-button {
   background-color: #19877c;
}
.city-map  button.proceed-button:hover {
   background-color: #0a574f;
}

@keyframes blink{
    0%{border-color: transparent;}
    50%{border-color: black;}
}

.proceed-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    cursor: pointer;
}

img.other-image {
    position: absolute;
    bottom: 0;
    z-index: 6;
    transform: translate(-25%, 50%);
}
img.other-image.other-image2 {transform: translate(80%, 60%);}

.console h3 {
    text-align: center;
    top: 50%;
    position: absolute;
    left: 0;
    width: 25vw;
    margin-top: 50px;
    background-color: #ffffff88;
    padding: 10px 20px;
    border-radius: 0.5em;
    transform: translateX(-25vw);
}

/* sistem z */
img.other-mapline {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
}

img.city-image {
    z-index: 0;
}

img.other-mapline.other-mapline2 {
    z-index: 2;
}
img.other-mapline.other-mapline0 {
    z-index: 2;
}

.city-map .col {
    z-index: 3;
}

.headmap {
    z-index: 4;
}


img.other-image {
    z-index: 6;
}


/* other town */
img.other-mapline.other-mapline2 {
    left: 0;
    top: 25%;
}
img.other-mapline.other-mapline1 {
    right: 0;
    top:-25%;
}

img.other-mapline.other-mapline0 {
    left: 70%;
    top: -20%;
}

.building.building04 img.build-image {
    padding-top: 25%;
}

.city-map .col.col-ed:last-child img.build-image {
    top: 12.5%;
}

img.mapline-track {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    z-index: 2;
}

.city-map .row.console {
    /* revisione 3 */
    width: auto !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 99;
    position: fixed!important;
}

/* Solo mobile */
@media only screen and (max-width: 600px) {
    img.build-image {
        width: 100%;
    }


    .col-12.col-sm-3.col {
        height: 25%!important;
    }

    .city-map {
        height: auto;
        font-size: 5em;
    }

    .city-map .building-labelspace {
        padding: 0 20px;
    }
    .city-map .col:nth-child(odd) img.build-image {
        bottom: none;
    }

    .city-map .col:nth-child(even) img.build-image {
        top: 0;
    }

    .city-map .col img.build-image{
        position: relative;
        width: 100%;
    }
    .city-map .building {
        font-size:1em;
    }
    .city-map .col-ed:first-child {
        padding-top: 100px!important;
    }

   
    .city-map .col img.build-image {
       width:80%;
    }
    .city-map {
        background-color: #456387;
    }

    img.other-mapline.other-mapline1 {
        top: 0%!important;
        width: 110%;
    }

    img.city-image {
        top: -10%!important;
    }

    img.other-image.other-image2 {transform: translate(28%, 4%)!important;}

    img.other-mapline.other-mapline2 {
        width: 110%;
    }

    .city-map .building {
        top: auto!important;
        bottom: 0!important;
    }

    .headmap {
        position: fixed!important;
    }

    .headmap img.logo-image {
        max-height: 70px;
    }

    .city-map .row.console button.proceed-button, .console h3 {
        top: 50%!important;
    }

    img.mapline-track {
        transform: rotate(60deg);
        width: 150% ! Important;
        position: fixed !important;
        margin-top: 100px;
        left: -25% !important;
    }

    .console h3 {width: 80vw;
        transform: translate(5%, 10%);
        position: relative;

    }
    .city-map .row.console button.proceed-button {
        font-size: 1.5em;
    }
    .main-content .city-map .row.console {
        top: 73%!important;
        height: auto!important;
    }


    .main-content .city-map .col {
        height: auto!important;
    }

    .main-content .city-map .row.console button.proceed-button {
        top: 0!important;
    }
    .console h3{
        margin-top:0
    }
    .main-content .city-map .col.col-ed:last-child {
        margin-bottom: 40vh!important;
    }

}

</style>
  