<template>
    <div class="person person-LineStyle" :style='{"border-color": preferences.color && preferences.color.hashCode ? preferences.color.hashCode : "gray"}'>
        <div class="content">
            <a @click="logOut" class="esci" >x</a>
        <img :src="require(`@/assets/img/${img}`)" class="me"/>
        <h3 class="persona" :style='{"background-color": preferences.color && preferences.color.hashCode ? preferences.color.hashCode : "gray"}'>{{userinfo.name}}</h3>
        </div>
    </div>
</template>

<script setup lang="ts">
import { AppUser, AuthService, PreferenceUserModelRequest } from '@/services/swagger';
import store from '@/store';
import { ref } from 'vue';

const preferences = ref<PreferenceUserModelRequest>(store.getters["UserModule/getUserPreferences"]);
const userinfo = ref<AppUser>(store.getters["UserModule/getUserState"])
const img = ref(store.getters["UserModule/getUserAvatarFileName"]);
const logOut = async () => {
    try{
      await AuthService.logout();
      store.commit("UserModule/logOut");
    }
    catch(err){
      console.log(err);
      
    }
}
</script>

<style scoped>
  .person.person-LineStyle img {
    width: 100%;
  }

  .person.person-LineStyle .me {
    border-radius: 100%;
    overflow: hidden;
  }

  h3.persona {
    z-index: 99;
    background-color: grey;
    padding: 0.25em 2em 0.25em 0.5em;
    border-radius: 0.25em;
    z-index: 1;
    white-space: nowrap;
    color: white;
    box-shadow: 5px 5px 5px black;
    left: 0%;
    transform: translateX(-50%) translateY(-50%) rotate(10deg) !important;
    top: 50%;
    text-align: left;
    font-size: 1.25em;
    position: absolute;
    width: 150%;

  }

  img.me {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .person .content {
    position: relative;
    width: 100%;
    height: 100%;
}
  .person a.esci {
    position: absolute;
    background-color: #007aea;
    color: white;
    text-decoration: none;
    padding: 0.2em 0.4em;
    z-index: 9;
    border-radius: 1em;
    line-height: 1em;
    font-size: 0.8em;
    aspect-ratio: 1/1;
    right: 0;
    cursor: pointer;
}

.person a.esci:hover {
    background-color: blue;
}

/* Solo mobile */
@media only screen and (max-width: 600px) {
  .person {
      min-width: 100px;
      height: 100px;
      font-size: 0.5em;
      position: fixed;
  }
  .person a.esci {
    font-size: 1.5em !important;
    margin-top: -0.5em;
  }
}
</style>
