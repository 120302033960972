import axios, { AxiosAdapter, AxiosRequestConfig, AxiosInstance } from "axios";
import store from "@/store";
import router from "@/router";
function CreateAxiosInstance(){
  const WebServicesURL = process.env.VUE_APP_WEBSERVICES_URL;
    const conf: AxiosRequestConfig = {
      baseURL: WebServicesURL,
      timeout: 60000,
      withCredentials: true,
      adapter: axios.defaults.adapter as AxiosAdapter
    }
    let axiosauthinstance:AxiosInstance;
    const token = store.getters['UserModule/getUserToken'];
    //Inserire token da store o da cookiee se è presente
    if(token){
      conf.headers = { 
        'Authorization': 'Bearer ' + token, 
        'Access-Control-Max-Age': 600, 
        'Cache-Control': 'no-cache', 
        'Pragma': 'no-cache', 
        'Expires': '0',
      };
      axiosauthinstance = axios.create(conf);
      axiosauthinstance.interceptors.request.use((config)=>{        
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      })
    }else{
      conf.headers = { 
        'Cache-Control': 'no-cache', 
        'Pragma': 'no-cache', 
        'Expires': '0',
      };
      axiosauthinstance = axios.create(conf);
    }
    
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axiosauthinstance.interceptors.response.use(undefined, error => {    
    if (error) {
      const originalRequest = error.config;
      if (error.message.includes("401") && !originalRequest._retry) {
        originalRequest._retry = true;
        //store.logOut();
        router.push({name: "page401"});
      }
      if (error.message.includes("400") && !originalRequest._retry) {
        originalRequest._retry = false;
      }
      if (error.message.includes("403") && !originalRequest._retry) {
        originalRequest._retry = false;
        router.push({name: "page403"});
      }            
      else if (error.message.includes("timeout of ") && !originalRequest._retry)
      {
        alert("Timeout waiting for the WebAPIs Engine");
      }
      return Promise.reject(error);
    }
  }); 
  return axiosauthinstance;
}
export { CreateAxiosInstance };