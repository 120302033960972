<template>
    <!--NAV-->
    <question-nav-component
        :missionName="missionName"
    >
    </question-nav-component>
    <!--CONTENT-->
    <div
        class="display"
        :style="{
            backgroundImage: backgroundImage ? `url(${require('@/assets/img/'+ backgroundImage)})` : 'none',
            backgroundSize: 'cover',
        }"
    >
        <slot name="question-content"></slot>
        <!--TOOLBAR-->
        <question-tool-bar-component
            @onGoAheadAction="emit('onGoAheadAction')"
            @onGoBackAction="emit('onGoBackAction')"
            @on-cancel-action="emit('onCancelAction')"
        />
    </div>
    <!--FOOTER-->
</template>

<script setup lang="ts">
import QuestionNavComponent from "@/components/Navbars/QuestionNavComponent.vue";
import QuestionToolBarComponent from "@/components/Toolbars/QuestionToolBarComponent.vue";
import { defineProps, onMounted, onBeforeUnmount, toRefs, withDefaults, defineEmits } from 'vue';
const body = document.getElementsByTagName("body")[0];
const emit = defineEmits<{
    (e: 'onGoAheadAction'): void
    (e: 'onGoBackAction'): void
    (e: 'onCancelAction'): void
}>();
onMounted(()=>{
    body.classList.add("virtual-reality");
})
onBeforeUnmount(()=>{
    body.classList.remove("virtual-reality");
})
const props = withDefaults(defineProps<{
    missionName?:string 
    backgroundImage?:string
}>(), {
    missionName: "",
    backgroundImage: ""
});

toRefs(props);
</script>

