<template>
<div class="Soft-Select-Color">
    <div class="vue-select-image">
    <ul class="vue-select-image__wrapper">
        <li v-for="(color, index) in colors" :key="index" class="vue-select-image__item">
        <div
            :class="{ active: selectedColor?.hashCode == color.hashCode }"
            class="vue-select-image-item"
            @click="selectColor(color.hashCode)"
            :style="{ backgroundColor: color.hashCode }"
        >
        </div>
        </li>
    </ul>
    </div>
</div>
</template>

<script setup lang="ts">
import { Color } from '@/services/swagger';
import { withDefaults, defineProps, defineEmits } from 'vue';

const props = withDefaults(defineProps<{
    colors:Color[],
    selectedColor?:Color | null
}>(),{
    colors: () => [],
});
const emit = defineEmits<{
    selectColorEvent: [color:Color]
}>();
const selectColor = (hashCode?:string) => {
    var values = props.colors;
    var selected = values?.find(c => c.hashCode == hashCode);
    emit("selectColorEvent", selected as Color)
}


</script>
<style scoped>

.Soft-Select-Color li.vue-select-image__item .vue-select-image-item {
    width: 70px;
    height: 70px;
    margin-right: 10px;
}

.Soft-Select-Color li.vue-select-image__item {
    margin-right: 1em;
}
.Soft-Select-Color .vue-select-image-item.active {
    border: 4px solid white;
}
</style>