import { CloseQuestionRequest } from "@/services/swagger";
import { Module, MutationTree } from "vuex";
import { RootState } from "./types";
import {Association} from '@/services/swagger'
interface QuestionAssociationModuleState {
    questionId:number,
    association:Association[]
}
const mutations: MutationTree<QuestionAssociationModuleState> = {
    updateQuestionId(state, newData:number){
        state.questionId = newData;
        state.association = [];
    },
    updateAssociation(state, newData:Association){
        state.association.push(newData);
    },
    cancelAssociations(state){
        state.association = []
    }
};
const getters = {
    getCloseQuestionRequest: (state: QuestionAssociationModuleState): CloseQuestionRequest=> {
        return new CloseQuestionRequest(
            {questionId: state.questionId, associations: state.association}
        )
    }
}
const QuestionAssociationModule:Module<QuestionAssociationModuleState, RootState> = {
    namespaced: true,
    state:{
        questionId: 0,
        association: []
    },
    mutations,
    actions:{

    },
    getters
}

export default QuestionAssociationModule