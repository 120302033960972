<template>
    <div 
        :class="{ 'building-completed': missionType?.isClosed }" 
        v-bind="$attrs" 
        :style="{'cursor:pointer':  missionType?.missionType?.id == currentBuildId}"
        @click="goToMission"
    >
        <a v-if="missionType?.missionType?.id == currentBuildId" name="here-active"></a>
        <div class="building-labelspace">
            <div class="etichetta" :class="{'enable-build': missionType?.missionType?.id == currentBuildId}">
            <span class="name">{{ missionType?.missionType?.buildName }}</span>
            <img v-if="(missionType?.missionType?.priority!) >= 1" src="@/assets/img/here.png" alt="Here" class="here-icon">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, toRefs} from 'vue';
import {AvaiableMissionType} from '@/services/swagger';

const props = defineProps<{
    missionType?:AvaiableMissionType,
    currentBuildId?: number
}>();
const emit = defineEmits<{
    onClickMission: [missionType:AvaiableMissionType]
}>();
toRefs(props);
const goToMission = () =>{
    if(props.missionType?.missionType?.id == props.currentBuildId)
    {
        if(props.missionType)
            emit('onClickMission', props.missionType);
    }
}
</script>

<style scoped lang="css">
.enable-build {
        border: 4px solid green;
    }

.city-map .building .etichetta span.name {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 19%, rgba(0, 0, 0, 0.5) 82%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 1) 100%);
    text-align: center;
    color: white;
}

.city-map .building-labelspace .etichetta {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center!important;
}

.city-map .building-labelspace {
    text-align: center;
}

.city-map .here-icon {
    width: 30px;
    height: 45px;
}

.etichetta.enable-build span.name {
    background-color: #3498db;
    animation: blinkeret 1s linear infinite;
}

@keyframes blinkeret {
  50% {
    background-color: #3498db;
  }
}


.etichetta.enable-build {
    border: none!important;
    cursor: pointer!important;
}

.city-map .building.building-completed .etichetta span.name {
    background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 50%) 19%, rgb(255 255 255 / 50%) 82%, rgb(255 255 255 / 0%) 100%, rgb(255 255 255) 100%);
    text-align: center;
    color: #919191;
    filter: blur(1px);
    font-size:0.8em;
    
}

.city-map .building.building-completed .etichetta img.here-icon {
    opacity: 0.5;
    filter: blur(2px);
}

.city-map .building .etichetta.enable-build span.name {
    font-size: 1.2em;
}
.building-completed .etichetta .name:after {
    position: absolute;
    content: "";
    background-color: #014482;
    width: 100%;
    height: 2px;
    transform: rotate(-10deg);
    left:0;
    top: 50%;
}

/* Solo mobile */
@media only screen and (max-width: 600px) {
    .etichetta {
        text-align: center;
    }

    .city-map .building-labelspace {
        padding: 0 20px;
    }
}

</style>