<template>
    <div class="inav">
        <nav
            class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
            v-bind="$attrs"
            id="navbarBlur"
            data-scroll="true"
        >
            <div class="px-3 py-1 container-fluid">
                <!-- <div id="main-title-id" class="gamename">{{ mainTitle }}</div> -->
                <div class="headmap-logo">
                    <img src="@/assets/img/logo_rev.svg" alt="Notaropoly" class="logo-image">
                </div>
                <div id="mission-name-id" class="wherename">{{ missionName }}</div>
            </div>
        </nav>
    </div>
</template>

<script setup lang="ts">
import { defineProps, toRefs, withDefaults, onMounted } from 'vue';

type OrderElements = {
    domId: string;
    order: number;
}
interface QuestionLayoutProps {
    mainTitle?: string,
    missionName?: string,
    orderElements?: OrderElements[]
}

const props = withDefaults(defineProps<QuestionLayoutProps>(), {
    missionName: "",
    mainTitle: "Notartopoli",
    orderElements: () => [
        {domId: 'main-title-id', order: 1},
        {domId: 'mission-name-id', order: 2}
    ]
});

toRefs(props);

onMounted(()=>{
    props.orderElements.forEach(element => {
        SetOrder(element.domId, element.order);
    });
})
const SetOrder = (elementId:string, order:number) => {
    const el = document.getElementById(elementId);
    if(!el) return;
    el.style.order = `${order}`;
}

</script>

<style>
.inav {
    position: absolute;
    z-index: 6;
    background-color: #ffffffaa;
    width: 100%;
    background-color: #ffffff55;
}

.headmap-logo {
    max-width: 250px;
    margin: 0 auto;
}
.headmap-logo img {
    width: 100%;
}
</style>